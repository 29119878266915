import React from 'react'
import PropTypes from 'prop-types'
import { Tag, TagLabel, TagRightIcon } from '@chakra-ui/react'

import { PRODUCT_STATUSES } from '~/constants/constants'
import CheckMarkCircle from '~/icons/CheckMarkCircle'
import XMarkCircle from '~/icons/XMarkCircle'

const statusNames = {
  [PRODUCT_STATUSES.APPROVED]: 'Активный',
  [PRODUCT_STATUSES.WAITING_APPROVE]: 'В\u00A0ожидании',
  [PRODUCT_STATUSES.WAITING_APPROVE_PACKAGES]: 'В\u00A0ожидании упаковок',
  [PRODUCT_STATUSES.REJECTED]: 'Отклонен'
}

const statusColors = {
  [PRODUCT_STATUSES.APPROVED]: { label: 'text.300', icon: 'custom.green' },
  [PRODUCT_STATUSES.WAITING_APPROVE]: { label: '#b7b2b0', icon: 'custom.blue' },
  [PRODUCT_STATUSES.WAITING_APPROVE_PACKAGES]: { label: '#b7b2b0', icon: 'custom.blue' },
  [PRODUCT_STATUSES.REJECTED]: { label: 'secondary.500', icon: 'secondary.500' }
}

const statusIcons = {
  [PRODUCT_STATUSES.APPROVED]: CheckMarkCircle,
  [PRODUCT_STATUSES.WAITING_APPROVE]: null,
  [PRODUCT_STATUSES.WAITING_APPROVE_PACKAGES]: null,
  [PRODUCT_STATUSES.REJECTED]: XMarkCircle
}

function ProductStatus (props) {
  const { status } = props

  const statusName = statusNames[status]
  const labelColor = statusColors[status]?.label
  const iconColor = statusColors[status]?.icon
  const icon = statusIcons[status]

  return (
    <Tag bg={'transparent'} px={'unset'}>
      <TagLabel color={labelColor} whiteSpace={'pre-wrap'}>{statusName}</TagLabel>
      {icon && (
        <TagRightIcon as={icon} color={iconColor} />
      )}
    </Tag>
  )
}

ProductStatus.propTypes = {
  status: PropTypes.string
}

export default ProductStatus
