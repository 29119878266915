import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, SimpleGrid, Stack } from '@chakra-ui/react'

import ProductPackages from './ProductPackages'

import * as API from '~/constants/api'
import * as CONST from '~/constants/constants'
import { DetailTitle } from '~/components/Titles'
import {
  HookForm,
  Input,
  Textarea,
  NumberInput,
  SelectField,
  SelectFieldStatic,
  FileDropzone
} from '~/components/HookForm'
import { LabelWrap } from '~/components/Wraps'
import { PRODUCT_STATUSES } from '~/constants/constants'

function ProductCreate (props) {
  const { onSubmit, isEdit, isLoading, isLoadingData, defaultValues, status } = props

  const title = isEdit ? 'Редактирование продукта' : 'Новый продукт'
  const isEditActiveStatus = isEdit && (status === PRODUCT_STATUSES.APPROVED)
  const requiredByEditActiveStatus = !isEditActiveStatus

  if (isLoadingData) {
    return (
      <Fragment>
        <DetailTitle>{title}</DetailTitle>

        Loading...
      </Fragment>
    )
  }

  return (
    <Fragment>
      <DetailTitle>{title}</DetailTitle>

      <HookForm onSubmit={onSubmit} defaultValues={defaultValues}>
        <Stack spacing={5}>
          <LabelWrap label={'Торговая марка'}>
            <Input
              name={'tradeMark'}
              rules={{ required: requiredByEditActiveStatus }}
              disabled={isEditActiveStatus}
            />
          </LabelWrap>

          {false && <LabelWrap label={'Подмарка'}>
            <Input name={'subMark'} />
          </LabelWrap>}

          <LabelWrap label={'Вторичная торговая марка'}>
            <Input
              name={'name'}
              rules={{ required: requiredByEditActiveStatus }}
              disabled={isEditActiveStatus}
            />
          </LabelWrap>

          <LabelWrap label={'Описание'}>
            <Textarea
              name={'description'}
              rules={{ required: requiredByEditActiveStatus }}
              disabled={isEditActiveStatus}
            />
          </LabelWrap>

          <LabelWrap label={'Тип упаковки'}>
            <Input
              name={'package'}
              rules={{ required: requiredByEditActiveStatus }}
              disabled={isEditActiveStatus}
            />
          </LabelWrap>

          {false && (
            <LabelWrap label={'Тип упаковки'}>
              <SelectFieldStatic
                name={'packageType'}
                list={[
                  { id: 'one', name: 'Test 1' },
                  { id: 'two', name: 'Test 2' },
                  { id: 'three', name: 'Test 3' }
                ]}
              />
            </LabelWrap>
          )}

          <LabelWrap label={'Кол-во и ед. измерения'}>
            <SimpleGrid columns={2} spacing={4}>
              <NumberInput
                name={'quantity'}
                rules={{ required: requiredByEditActiveStatus }}
                disabled={isEditActiveStatus}
              />

              <SelectFieldStatic
                name={'measurement'}
                list={CONST.MEASUREMENT.list}
                rules={{ required: requiredByEditActiveStatus }}
                isDisabled={isEditActiveStatus}
              />
            </SimpleGrid>
          </LabelWrap>

          <LabelWrap label={'Целевые рынки'}>
            <SelectField
              name={'targetMarket'}
              api={API.COUNTRY_LIST}
              isMulti={true}
              closeMenuOnSelect={false}
              rules={{ required: requiredByEditActiveStatus }}
              isDisabled={isEditActiveStatus}
            />
          </LabelWrap>

          <LabelWrap label={'Brick code'}>
            <SelectField
              name={'productType'}
              api={API.PRODUCT_TYPE_LIST}
              params={{ limit: 5, level: 3 }}
              rules={{ required: requiredByEditActiveStatus }}
              isDisabled={isEditActiveStatus}
            />
          </LabelWrap>

          <LabelWrap label={'Фото упаковки'}>
            <FileDropzone
              name={'files'}
              isMulti={true}
              maxSize={5 * 1024 * 1024}
              aclType={'public-read'}
            />
          </LabelWrap>
        </Stack>

        <Stack mt={5} pl={44} spacing={10}>
          <ProductPackages />

          <Button isFullWidth={true} isLoading={isLoading} type={'submit'}>
            Сохранить
          </Button>
        </Stack>
      </HookForm>
    </Fragment>
  )
}

ProductCreate.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingData: PropTypes.bool,
  defaultValues: PropTypes.object,
  status: PropTypes.string
}

export default ProductCreate
