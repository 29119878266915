import React from 'react'
import { useFieldArray } from 'react-hook-form'
import { Delete } from 'react-iconly'
import { Box, Flex, IconButton, SimpleGrid, Stack } from '@chakra-ui/react'

import * as CONST from '~/constants/constants'
import { Input, NumberInput, SelectFieldStatic } from '~/components/HookForm'
import { FieldArrayButton } from '~/components/Buttons'

export default function ProductPackages () {
  const name = 'packages'

  const { fields, append, remove } = useFieldArray({
    name
  })

  function onAppend () {
    append({
      package: '',
      quantity: '',
      measurement: '',
      isClearable: true
    })
  }

  return (
    <Stack spacing={5}>
      {fields.map((field, index) => (
        <Stack key={field.id} spacing={5}>
          <Flex>
            <Box flexGrow={1} mr={field.isClearable ? 5 : 0}>
              <Input
                name={`${name}.${index}.package`}
                placeholder={'Тип упаковки'}
                rules={{ required: true }}
                defaultValue={field.package}
              />
            </Box>

            {field.isClearable
              ? (
                <IconButton
                  aria-label={'Remove item'}
                  color={'primary.900'}
                  colorScheme={'gray'}
                  icon={<Delete />}
                  mt={'6px'}
                  size={'md'}
                  variant={'ghost'}
                  onClick={() => remove(index)}
                />
              )
              : null}
          </Flex>

          <SimpleGrid columns={2} spacing={4}>
            <NumberInput
              name={`${name}.${index}.quantity`}
              placeholder={'Кол-во'}
              rules={{ required: true }}
              defaultValue={field.quantity}
            />

            <SelectFieldStatic
              name={`${name}.${index}.measurement`}
              list={CONST.MEASUREMENT.list}
              placeholder={'Ед. изм.'}
              rules={{ required: true }}
              defaultValue={field.measurement}
            />
          </SimpleGrid>
        </Stack>
      ))}

      <FieldArrayButton onClick={onAppend}>
        Добавить упаковку
      </FieldArrayButton>
    </Stack>
  )
}
