import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { defaultTo, filter as ramFilter, join, pipe, split } from 'ramda'

import { TableContext } from './TableContext'
import Table from './Table'

import { useAddParamsURL, useGetSearchParams } from '~/hooks/urls'

function TableProvider (props) {
  const {
    children,
    list,
    isLoading,
    isSelectable,
    filter,
    filterForm,
    spacing
  } = props

  const addParamsURL = useAddParamsURL()
  const { page, pageSize, select, product } = useGetSearchParams()

  const checkedList = pipe(
    defaultTo(''),
    split(','),
    ramFilter(Boolean)
  )(select)

  const onCheckRow = useCallback((values) => {
    const stringCheckedValues = join(',', values)

    addParamsURL({
      select: stringCheckedValues
    })
  }, [page, pageSize, product])

  const tableProps = {
    list,
    isLoading,
    filter,
    filterForm,
    isSelectable,
    rowMinHeight: 50,
    spacing,
    checkedList,
    onCheckRow
  }

  return (
    <TableContext.Provider value={tableProps}>
      <Table>{children}</Table>
    </TableContext.Provider>
  )
}

export function useTable () {
  return useContext(TableContext)
}

TableProvider.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  list: PropTypes.array,
  isLoading: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  spacing: PropTypes.number,
  filter: PropTypes.object,
  filterForm: PropTypes.node
}

TableProvider.defaultProps = {
  isCreatable: true,
  spacing: 6
}

export default TableProvider
